import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { AnimatePresence, type Variants, motion } from 'framer-motion'
import React from 'react'
import { usePlentyMediaQuery } from '#app/hooks/use-plenty-media-query'
import { type TabsStoryblok } from '#types/component-types-sb'
import PlentyBody from '../typography/plenty-body'
import { Alert } from '../ui/alert'
import { Icon } from '../ui/icon'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs'

interface TabsBlokProps {
	blok: TabsStoryblok
}
const tabContentVariants: Variants = {
	initial: {
		y: 10,
		opacity: 0,
	},
	enter: {
		y: 0,
		opacity: 1,
	},
	exit: {
		y: -10,
		opacity: 0,
	},
}
const TabsBlok = ({ blok }: TabsBlokProps) => {
	const [value, setValue] = React.useState<string | undefined>()
	const isDesktop = usePlentyMediaQuery('(min-width: 820px)')

	if (!blok.tabs) return
	return (
		<Tabs
			{...storyblokEditable(blok)}
			className="flex w-full flex-col"
			defaultValue={blok.tabs[0]._uid}
			value={value}
			onValueChange={setValue}
		>
			{isDesktop ? (
				<div className="flex flex-col">
					<div className="mb-4 flex w-full flex-col justify-between md:flex-row">
						<TabsList className="flex h-fit w-full flex-col justify-between  md:w-full md:flex-row md:text-left">
							{blok.tabs.map(tab => {
								return (
									<TabsTrigger
										value={tab._uid}
										key={`${tab._uid}-trigger`}
										className="justify-center md:justify-between"
									>
										<PlentyBody>{tab.title}</PlentyBody>
										<Icon
											name="chevron-down"
											size="lg"
											className="transition-all group-data-[state=active]:rotate-180"
										/>
									</TabsTrigger>
								)
							})}
						</TabsList>
					</div>
					{blok.tabs.map(tab => {
						return (
							<TabsContent value={tab._uid} key={`${tab._uid}-content`}>
								<AnimatePresence mode="wait">
									<motion.div
										variants={tabContentVariants}
										initial="initial"
										animate="enter"
										exit="exit"
										transition={{
											duration: 0.3,
										}}
									>
										<Alert className="relative">
											<>
												<Icon
													name="close"
													className="absolute right-4 top-4"
													size="lg"
													onClick={() => setValue('')}
												/>

												{tab.content.map((blok: any) => (
													<StoryblokComponent key={blok._uid} blok={blok} />
												))}
											</>
										</Alert>
									</motion.div>
								</AnimatePresence>
							</TabsContent>
						)
					})}
				</div>
			) : (
				<div className="flex flex-col">
					<div className="mb-4 flex w-full flex-col justify-between md:flex-row">
						<TabsList className="flex h-fit w-full flex-col justify-between  md:flex-row md:text-left">
							{blok.tabs.map(tab => {
								return (
									<div key={`${tab._uid}-mobile`} className="w-full">
										<TabsTrigger
											value={tab._uid}
											className="justify-between"
											key={`${tab._uid}-trigger-mobile`}
										>
											<PlentyBody>{tab.title}</PlentyBody>
											<Icon
												name="chevron-down"
												size="lg"
												className="transition-all group-data-[state=active]:rotate-180"
											/>
										</TabsTrigger>
										<TabsContent
											value={tab._uid}
											key={`${tab._uid}-content-mobile`}
										>
											<AnimatePresence mode="wait">
												<motion.div
													variants={tabContentVariants}
													initial="initial"
													animate="enter"
													exit="exit"
													transition={{
														duration: 0.3,
													}}
												>
													<Alert className="relative">
														<>
															<Icon
																name="close"
																className="absolute right-4 top-4"
																size="lg"
																onClick={() => setValue('')}
															/>

															{tab.content.map((blok: any) => (
																<StoryblokComponent
																	key={`${blok._uid}-mobile`}
																	blok={blok}
																/>
															))}
														</>
													</Alert>
												</motion.div>
											</AnimatePresence>
										</TabsContent>
									</div>
								)
							})}
						</TabsList>
					</div>
				</div>
			)}
		</Tabs>
	)
}

export default TabsBlok
