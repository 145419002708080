import { storyblokEditable } from '@storyblok/react'
import {
	MediaPlayer,
	type MediaPlayerInstance,
	MediaProvider,
	Track,
	Poster,
} from '@vidstack/react'
import { useRef } from 'react'
import { ClientOnly } from 'remix-utils/client-only'
import { usePlentyMediaQuery } from '#app/hooks/use-plenty-media-query'
import { cn } from '#app/utils/misc'
import { type VideoPlayerStoryblok } from '#types/component-types-sb'
import { VideoLayout } from '../video-player/video-layout'

const VideoPlayer = ({ blok }: { blok: VideoPlayerStoryblok }) => {
	const isDesktop = usePlentyMediaQuery('(min-width: 820px)')
	let player = useRef<MediaPlayerInstance>(null)

	const textTracks = [
		{
			src: blok.pathDanishSubtitles,
			label: 'Danish',
			language: 'da',
			kind: 'subtitles',
			default: true,
		},
		{
			src: blok.pathEngSubtitles,
			label: 'English',
			language: 'en',
			kind: 'subtitles',
		},
	] as const

	return (
		<ClientOnly fallback={<></>}>
			{() => {
				return (
					<div
						{...storyblokEditable(blok)}
						key={blok._uid}
						className="m-auto flex max-w-[1500px]"
					>
						<MediaPlayer
							className={cn(
								'ring-media-focus relative aspect-auto w-full overflow-hidden bg-slate-900 font-sans text-white data-[focus]:ring-4 md:aspect-video',
							)}
							src={
								isDesktop ? blok.video?.filename : blok.mobileVideo?.filename
							}
							ref={player}
							playsInline
							autoPlay
							muted
							loop
						>
							<MediaProvider>
								<Poster
									className="absolute inset-0 block h-full w-full rounded-md object-cover opacity-0 transition-opacity data-[visible]:opacity-100"
									src={
										isDesktop
											? blok.videoCover?.filename
											: blok.videoMobileCover?.filename
									}
									alt={
										isDesktop
											? blok.videoCover?.alt
											: blok.videoMobileCover?.alt
									}
								/>
								{textTracks.map(track => (
									<Track {...track} key={track.src} />
								))}
							</MediaProvider>
							<VideoLayout />
						</MediaPlayer>
					</div>
				)
			}}
		</ClientOnly>
	)
}

export default VideoPlayer
