import * as Tooltip from '@radix-ui/react-tooltip';
import { Captions, Controls, Gesture } from '@vidstack/react';
import * as Buttons from './buttons';
import captionStyles from './captions.module.css';

// import * as Menus from './menus'

// Offset tooltips/menus/slider previews in the lower controls group so they're clearly visible.
const popupOffset = 30;

export interface VideoLayoutProps {
  thumbnails?: string;
}

export function VideoLayout() {
  return (
    <>
			<Gestures />
			<Captions
        className={`${captionStyles.captions} media-preview:opacity-0 media-controls:bottom-[5px] media-captions:opacity-100 absolute inset-0 bottom-2 z-10 select-none break-words opacity-0 transition-[opacity,bottom] duration-300`} />

			<Controls.Root className="media-controls:opacity-100 absolute inset-0 z-10 flex h-full w-full flex-col bg-gradient-to-t from-black/10 to-transparent transition-opacity">
				<Tooltip.Provider>
					<div className="flex-1" />

					<Controls.Group className="-mt-0.5 flex w-full items-center px-2 pb-2">
						<div className="flex-1" />
						<Buttons.Mute tooltipOffset={popupOffset} />
						{/* <Menus.Captions offset={popupOffset} tooltipOffset={popupOffset} /> */}
					</Controls.Group>
				</Tooltip.Provider>
			</Controls.Root>
		</>);

}

function Gestures() {
  return (
    <>
			<Gesture
        className="absolute inset-0 z-0 block h-full w-full"
        event="pointerup"
        action="toggle:paused" />

			<Gesture
        className="absolute left-0 top-0 z-10 block h-full w-1/5"
        event="dblpointerup"
        action="seek:-10" />

			<Gesture
        className="absolute right-0 top-0 z-10 block h-full w-1/5"
        event="dblpointerup"
        action="seek:10" />

		</>);

}